import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import User from "./scenes/user_list";
import FAQ from "./scenes/faq";
import NotificationPage from "./scenes/notificationpage";
import Maintenance from "./scenes/maintanace";
import Profile from "./components/Profile";
import FileUpload from "./scenes/file_upload";
import News from "./scenes/news"; // Import the News component
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user" element={<User />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/notificationpage" element={<NotificationPage />} />
              <Route path="/maintanace" element={<Maintenance />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/file_upload" element={<FileUpload />} />
              <Route path="/news" element={<News />} /> {/* Add the news route */}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;