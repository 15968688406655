import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputBase,
  IconButton,
  Typography,
  Button,
  Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#15162C',
  padding: '20px',
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#3A3B5A',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#fff',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#3A3B5A',
  borderRadius: '3px',
  padding: '5px',
  width: '300px',
  marginBottom: '20px',
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff4d4d',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff1a1a',
  },
}));

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://adminpage.minbertv.com/api/users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token for authentication
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
        setErrorMessage('Failed to fetch users.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Filter users based on the search term
  const filteredUsers = users.filter((user) =>
    user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone_number.includes(searchTerm)
  );

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      const originalUsers = [...users]; // Save original users for rollback
      setUsers(users.filter((user) => user.id !== userId)); // Optimistically update UI

      try {
        await axios.delete(`https://adminpage.minbertv.com/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token for authentication
          },
        });
        setSuccessMessage('User deleted successfully!');
      } catch (error) {
        console.error('Error deleting user:', error);
        setUsers(originalUsers); // Rollback to original state if error occurs
        setErrorMessage('Failed to delete user. Please try again.');
      }
    }
  };

  if (loading) {
    return <Typography variant="h6" sx={{ color: '#fff' }}>Loading...</Typography>;
  }

  return (
    <StyledPaper>
      <Typography variant="h1" sx={{ color: '#00C8C8', fontSize: 32, fontWeight: 700, textAlign: 'left', marginBottom: '20px' }}>
        USER LIST
      </Typography>
      <Typography variant="body1" sx={{ color: '#9BA6B2', marginBottom: '20px' }}>
        List of users
      </Typography>

      {/* SEARCH BAR */}
      <SearchContainer>
        <InputBase
          sx={{ ml: 2, flex: 1, color: '#fff' }}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon sx={{ color: '#fff' }} />
        </IconButton>
      </SearchContainer>

      <TableContainer component={StyledPaper}>
        <Table aria-label="simple table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell><strong>ID</strong></StyledTableCell>
              <StyledTableCell><strong>First Name</strong></StyledTableCell>
              <StyledTableCell><strong>Last Name</strong></StyledTableCell>
              <StyledTableCell><strong>Phone Number</strong></StyledTableCell>
              <StyledTableCell><strong>Actions</strong></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {filteredUsers.map((user, index) => (
              <TableRow key={user.id} sx={{ backgroundColor: index % 2 === 0 ? '#2C2E48' : 'inherit' }}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{user.first_name}</StyledTableCell>
                <StyledTableCell>{user.last_name}</StyledTableCell>
                <StyledTableCell>{user.phone_number}</StyledTableCell>
                <StyledTableCell>
                  <DeleteButton onClick={() => handleDelete(user.id)}>Delete</DeleteButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for error and success messages */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </StyledPaper>
  );
};

export default UserList;