import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Card, CardContent, Typography, Box, FormControlLabel, Switch, Snackbar, CircularProgress } from '@mui/material';

const MAINTENANCE_API_URL = 'https://adminpage.minbertv.com/api/maintenance'; // API endpoint constant

const MaintenanceMode = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch current maintenance status from the API
  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await axios.get(MAINTENANCE_API_URL);
        setIsMaintenance(response.data.isMaintenance);
      } catch (error) {
        console.error('Failed to fetch maintenance status', error);
        setError('Failed to fetch maintenance status.');
      } finally {
        setLoading(false);
      }
    };
    fetchMaintenanceStatus();
  }, []);

  // Toggle maintenance mode
  const handleToggle = async (event) => {
    const newStatus = event.target.checked;
    setIsMaintenance(newStatus);
    
    // Update the status in the backend
    try {
      await axios.post(MAINTENANCE_API_URL, { isMaintenance: newStatus });
    } catch (error) {
      console.error('Failed to update maintenance status', error);
      setError('Failed to update maintenance status.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 2, p: 3, backgroundColor: isMaintenance ? '#ffe0e0' : '#000000' }}>
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={2}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold', 
                mb: 2, 
                color: isMaintenance ? '#000000' : '#ffffff' 
              }}
            >
              Maintenance Mode
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    checked={isMaintenance}
                    onChange={handleToggle}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                          color: '#fff',
                          transform: 'translateX(16px)',
                        },
                        '&.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#4caf50',
                        },
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: '#ccc',
                      },
                    }}
                  />
                }
                label={isMaintenance ? 'Maintenance On' : 'Maintenance Off'}
                labelPlacement="top"
                sx={{
                  fontSize: '1.2rem',
                  color: isMaintenance ? '#d32f2f' : '#4caf50',
                  fontWeight: '500',
                }}
              />
            )}
          </Box>
          <Typography 
            variant="body1" 
            sx={{ 
              textAlign: 'center', 
              fontSize: '1.1rem', 
              color: isMaintenance ? '#d32f2f' : '#388e3c' 
            }}
          >
            {isMaintenance 
              ? 'The app is currently in maintenance mode. Please check back later.' 
              : 'The app is functioning normally.'}
          </Typography>
        </CardContent>
      </Card>
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError('')}
        message={error} 
      />
    </Container>
  );
};

export default MaintenanceMode;