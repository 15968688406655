import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import
import { useNavigate } from 'react-router-dom'; // For navigation
import './Profile.css'; // Import your custom CSS file

function Profile() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [maskedEmail, setMaskedEmail] = useState('');
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setEmail(decoded.email);

        const emailParts = decoded.email.split('@');
        const masked = `${emailParts[0][0]}****${emailParts[0][emailParts[0].length - 1]}@${emailParts[1]}`;
        setMaskedEmail(masked);
      } catch (error) {
        console.error('Invalid token', error);
      }
    }
  }, [token]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (!token) {
      alert('No valid session found. Please log in again.');
      return;
    }

    try {
      await axios.post(
        'https://adminpage.minbertv.com/change-password',
        {
          email,
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Password updated successfully!');
    } catch (error) {
      console.error('Error changing password:', error.response || error.message);
      if (error.response && error.response.status === 401) {
        alert('Unauthorized. Please log in again.');
      } else {
        alert('Error changing password. Please try again.');
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear the token from localStorage
    navigate('/login'); // Redirect to the login page
  };

  if (!token) {
    return <div className="profile-message">Please log in to view your profile.</div>;
  }

  return (
    <div className="profile-container">
      <h1>Profile</h1>
      <p className="masked-email">Email: {maskedEmail}</p>
      
      {/* Change Password Form */}
      <form onSubmit={handleChangePassword} className="change-password-form">
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Current Password"
          required
          className="input-field"
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
          className="input-field"
        />
        <button type="submit" className="change-password-button">Change Password</button>
      </form>

      {/* Logout Button */}
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </div>
  );
}

export default Profile;
