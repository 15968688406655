import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Alert
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const API_URL = 'https://adminpage.minbertv.com/api/faqs'; // Update the API URL for FAQs

const FAQPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(API_URL);
      setFaqs(response.data);
    } catch (err) {
      setError('Failed to fetch FAQs');
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const newFaq = { question, answer };

    try {
      if (editId) {
        await axios.put(`${API_URL}/${editId}`, newFaq);
        setEditId(null);
        setSuccess('FAQ updated successfully!');
      } else {
        await axios.post(API_URL, newFaq);
        setSuccess('FAQ created successfully!');
      }

      setQuestion('');
      setAnswer('');
      fetchFaqs();
    } catch (err) {
      setError('Failed to submit FAQ');
    }
  };

  const handleEdit = (faq) => {
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setEditId(faq.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchFaqs();
      setSuccess('FAQ deleted successfully!');
    } catch (err) {
      setError('Failed to delete FAQ');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '40px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" gutterBottom>
            FAQs
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12}>
                <TextField 
                  label="Question" 
                  value={question} 
                  onChange={(e) => setQuestion(e.target.value)} 
                  fullWidth 
                  required 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Answer" 
                  value={answer} 
                  onChange={(e) => setAnswer(e.target.value)} 
                  fullWidth 
                  multiline 
                  rows={4} 
                  required 
                />
              </Grid>
            </Grid>

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <Button variant="contained" color="primary" type="submit" fullWidth>
              {editId ? 'Update FAQ' : 'Create FAQ'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <TableContainer component={Paper} style={{ marginTop: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="h6">Question</Typography></TableCell>
              <TableCell><Typography variant="h6">Answer</Typography></TableCell>
              <TableCell><Typography variant="h6">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs.map((faq) => (
              <TableRow key={faq.id}>
                <TableCell>{faq.question}</TableCell>
                <TableCell>{faq.answer}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(faq)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(faq.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default FAQPage;