import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../../components/Header";
import axios from 'axios';
import { useEffect, useState } from "react";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State to hold the number of users
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    // Fetch the number of users from the API
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://adminpage.minbertv.com/api/users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include token for authentication
          },
        });
        setUserCount(response.data.length); // Set the total number of users
      } catch (error) {
        console.error('Failed to fetch user count:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </Box>

      {/* STAT BOX */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor={colors.primary[400]}
        borderRadius="8px"
        p="20px"
        width="250px"
        height="150px"
      >
        {/* ICON */}
        <PersonAddIcon
          sx={{
            fontSize: "40px",
            color: colors.greenAccent[500],
            mb: "10px",  // Margin bottom to create space between the icon and text
          }}
        />

        {/* TOTAL USERS TEXT */}
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          TOTAL NUMBER OF USERS
        </Typography>

        {/* NUMBER */}
        <Typography
          variant="h1"
          fontWeight="bold"
          color={colors.greenAccent[500]}
          sx={{ mt: "10px" }}
        >
          {userCount}
        </Typography>
      </Box>
    </Box>
  );
};

export default Dashboard;