import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Alert
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

const API_URL = 'https://adminpage.minbertv.com/api/notifications'; // Update the API URL for notifications

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [title, setTitle] = useState('');
  const [remark, setRemark] = useState('');
  const [duration, setDuration] = useState(0);
  const [editId, setEditId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(API_URL);
      setNotifications(response.data);
    } catch (err) {
      setError('Failed to fetch notifications');
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const newNotification = { title, remark, duration };

    try {
      if (editId) {
        await axios.put(`${API_URL}/${editId}`, newNotification);
        setEditId(null);
        setSuccess('Notification updated successfully!');
      } else {
        await axios.post(API_URL, newNotification);
        setSuccess('Notification created successfully!');
      }

      // Reset form fields
      setTitle('');
      setRemark('');
      setDuration(0);
      fetchNotifications();
    } catch (err) {
      setError('Failed to submit notification');
    }
  };

  const handleEdit = (notification) => {
    setTitle(notification.title);
    setRemark(notification.remark);
    setDuration(notification.duration);
    setEditId(notification.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchNotifications();
      setSuccess('Notification deleted successfully!');
    } catch (err) {
      setError('Failed to delete notification');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '40px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Title" 
                  value={title} 
                  onChange={(e) => setTitle(e.target.value)} 
                  fullWidth 
                  required 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Remark" 
                  value={remark} 
                  onChange={(e) => setRemark(e.target.value)} 
                  fullWidth 
                  multiline 
                  rows={4} 
                  required 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Duration (seconds)" 
                  type="number" 
                  value={duration} 
                  onChange={(e) => setDuration(Math.max(0, e.target.value))} 
                  fullWidth 
                  required 
                  InputProps={{ inputProps: { min: 0 } }} 
                />
              </Grid>
            </Grid>

            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}

            <Button variant="contained" color="primary" type="submit" fullWidth>
              {editId ? 'Update Notification' : 'Create Notification'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <TableContainer component={Paper} style={{ marginTop: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="h6">Title</Typography></TableCell>
              <TableCell><Typography variant="h6">Remark</Typography></TableCell>
              <TableCell><Typography variant="h6">Duration (seconds)</Typography></TableCell>
              <TableCell><Typography variant="h6">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((notification) => (
              <TableRow key={notification.id}>
                <TableCell>{notification.title}</TableCell>
                <TableCell>{notification.remark}</TableCell>
                <TableCell>{notification.duration}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(notification)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(notification.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default NotificationPage;