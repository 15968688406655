// src/News.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const API_URL = 'https://adminpage.minbertv.com/api/news'; // Adjust this based on your API

const News = () => {
  const [title, setTitle] = useState('');
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Fetch news articles when the component mounts
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get(API_URL);
      setNewsList(response.data);
    } catch (err) {
      console.error('Error fetching news:', err);
    }
  };

  const handleImage1Change = (event) => {
    setImage1(event.target.files[0]);
  };

  const handleImage2Change = (event) => {
    setImage2(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!title || !description) {
      setError('Please enter a title and description');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image1) formData.append('image_1', image1);
    if (image2) formData.append('image_2', image2);

    setUploading(true);
    setMessage('');
    setError('');

    try {
      if (editingId) {
        await axios.put(`${API_URL}/${editingId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('News updated successfully!');
      } else {
        await axios.post(API_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('News created successfully!');
      }
      resetForm();
      fetchNews(); // Now fetchNews is defined and can be called
    } catch (error) {
      console.error('Error uploading news:', error);
      setError('Error uploading news: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setImage1(null);
    setImage2(null);
    setDescription('');
    setEditingId(null);
  };

  const handleEdit = (news) => {
    setTitle(news.title);
    setImage1(null); // Resetting image inputs
    setImage2(null); // Resetting image inputs
    setDescription(news.description);
    setEditingId(news.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      setMessage('News deleted successfully!');
      fetchNews(); // Refresh the news list after deletion
    } catch (err) {
      console.error('Error deleting news:', err);
      setError('Failed to delete news');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '40px', paddingBottom: '40px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" gutterBottom>
            News Management
          </Typography>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required
            style={{ marginBottom: '20px' }}
          />
          <Typography variant="body1" style={{ marginBottom: '8px' }}>
            Select the first image:
          </Typography>
          <input type="file" accept="image/*" onChange={handleImage1Change} />
          <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '8px' }}>
            Select the second image (optional):
          </Typography>
          <input type="file" accept="image/*" onChange={handleImage2Change} />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
            multiline
            rows={4}
            style={{ margin: '20px 0' }}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpload} 
            disabled={uploading} 
            style={{ marginTop: '20px', width: '100%' }}
          >
            {uploading ? 'Uploading...' : (editingId ? 'Update News' : 'Create News')}
          </Button>
          {message && <Alert severity="success" style={{ marginTop: '20px' }}>{message}</Alert>}
          {error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}
        </CardContent>
      </Card>

      <Card variant="outlined" style={{ marginTop: '30px' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            News Articles
          </Typography>
          <List>
            {newsList.map((news) => (
              <ListItem key={news.id}>
                <ListItemText
                  primary={news.title}
                  secondary={news.description}
                />
                {news.image_1 && (
                  <img 
                    src={news.image_1} 
                    alt="Image 1" 
                    style={{ width: '50px', height: '50px', marginRight: '10px' }} 
                  />
                )}
                {news.image_2 && (
                  <img 
                    src={news.image_2} 
                    alt="Image 2" 
                    style={{ width: '50px', height: '50px', marginRight: '10px' }} 
                  />
                )}
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(news.id)}>
                  <Delete />
                </IconButton>
                <Button onClick={() => handleEdit(news)} color="primary" size="small">Edit</Button>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default News;