import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Box
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const API_URL = 'https://adminpage.minbertv.com/api/uploads'; // Adjust based on your API

const VideoUpload = () => {
  const [title, setTitle] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [editingId, setEditingId] = useState(null);

  // Fetch uploads when the component mounts
  useEffect(() => {
    fetchUploads();
  }, []);

  const fetchUploads = async () => {
    try {
      const response = await axios.get(API_URL);
      setUploads(response.data);
    } catch (err) {
      console.error('Error fetching uploads:', err);
    }
  };

  const handleVideoChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleThumbnailChange = (event) => {
    setThumbnailFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!title || !videoFile) {
      setError('Please enter a title and select a video file');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', videoFile);
    if (thumbnailFile) formData.append('thumbnail', thumbnailFile);

    setUploading(true);
    setMessage('');
    setError('');

    try {
      if (editingId) {
        await axios.put(`${API_URL}/${editingId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('Upload updated successfully!');
      } else {
        await axios.post(API_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('Upload created successfully!');
      }
      resetForm();
      fetchUploads(); // Refresh the uploads list
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Error uploading file: ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setVideoFile(null);
    setThumbnailFile(null);
    setEditingId(null);
  };

  const handleEdit = (upload) => {
    setTitle(upload.title);
    setVideoFile(null); // Resetting video input
    setThumbnailFile(null); // Resetting thumbnail input
    setEditingId(upload.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      setMessage('Upload deleted successfully!');
      fetchUploads(); // Refresh the uploads list after deletion
    } catch (err) {
      console.error('Error deleting upload:', err);
      setError('Failed to delete upload');
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '40px', paddingBottom: '40px' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Video Upload
          </Typography>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            required
            style={{ marginBottom: '20px' }}
          />
          <Typography variant="body1" style={{ marginBottom: '8px' }}>
            Select a video file:
          </Typography>
          <input type="file" accept="video/*" onChange={handleVideoChange} />
          <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '8px' }}>
            Select a thumbnail image (optional):
          </Typography>
          <input type="file" accept="image/*" onChange={handleThumbnailChange} />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUpload} 
            disabled={uploading} 
            style={{ marginTop: '20px', width: '100%' }}
          >
            {uploading ? 'Uploading...' : (editingId ? 'Update Upload' : 'Upload')}
          </Button>
          {message && <Alert severity="success" style={{ marginTop: '20px' }}>{message}</Alert>}
          {error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}
        </CardContent>
      </Card>

      <Card variant="outlined" style={{ marginTop: '30px' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Uploaded Videos
          </Typography>
          <Grid container spacing={2}>
            {uploads.map((upload) => (
              <Grid item xs={12} sm={6} md={4} key={upload.id}>
                <Card variant="outlined">
                  <CardContent>
                    <ListItem>
                      <ListItemText
                        primary={upload.title}
                        secondary={upload.file_path}
                      />
                      {upload.thumbnail_path && (
                        <img 
                          src={`http://127.0.0.1:8000/storage/${upload.thumbnail_path}`} 
                          alt="Thumbnail" 
                          style={{ width: '100%', height: 'auto', marginBottom: '10px' }} 
                        />
                      )}
                      <div>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(upload.id)}>
                          <Delete />
                        </IconButton>
                        <Button onClick={() => handleEdit(upload)} color="primary" size="small">Edit</Button>
                      </div>
                    </ListItem>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VideoUpload;